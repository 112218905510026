import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-speech-bubble',

  imports: [],
  templateUrl: './speech-bubble.component.html',
  styleUrls: ['./speech-bubble.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpeechBubbleComponent {}
