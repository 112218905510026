import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { HotelPhoto } from '@api-clients/api-client';
import { TourContent } from '@api-clients/api-client/models/tour-content';
import { Observable, of } from 'rxjs';
import { GalleryItem } from '../../../../../interfaces/gallery.interface';
import { GalleryService } from '../../../../../shared/services/gallery.service';
import { CheckboxRoundedComponent } from '../../../../../ui-components/checkbox-rounded/checkbox-rounded.component';
import {
  ContentCreatorItemComponent,
  ContentCreatorItemMessage,
} from '../../../interfaces/content-creator.interface';

interface HotelPhotoWithPreview extends HotelPhoto {
  previewUrl: string;
  s3Url: string;
  imageForViewer: GalleryItem;
}

@Component({
  selector: 'app-tour-content-photos',

  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CheckboxRoundedComponent],
  providers: [GalleryService],
})
export class PhotosComponent implements ContentCreatorItemComponent, OnInit {
  @Input() public tourId: string;
  @Input() public tourContent: TourContent;
  @Input() public disableSelection = false;

  public photos: HotelPhotoWithPreview[] = [];
  public visiblePhotos: HotelPhotoWithPreview[] = [];
  public hiddenPhotosCount: number;
  public selectedPhotos = new Set<HotelPhotoWithPreview>();
  public isGalleryOpen = false;

  private readonly cdr = inject(ChangeDetectorRef);
  private readonly galleryService = inject(GalleryService);

  ngOnInit(): void {
    if (this.tourContent.hotel?.photos) {
      this.photos = this.tourContent.hotel.photos.map(photo => ({
        ...photo,
        previewUrl: 'https://smart-like.com/img/h/sm4x3/' + photo.url,
        s3Url: 'https://smart-like.com/img/h/big/' + photo.url,
        imageForViewer: {
          previewUrl: 'https://smart-like.com/img/h/sm4x3/' + photo.url,
          contentLink: 'https://smart-like.com/img/h/big/' + photo.url,
        },
      }));
    }
    // Выделим первые 5 фото
    this.selectedPhotos = new Set(this.photos.slice(0, 3));
    // Показываем 13 фото. Это два ряда
    this.visiblePhotos = this.photos.slice(0, 13);
    this.hiddenPhotosCount = this.photos.length - this.visiblePhotos.length;
  }

  togglePhotoSelection(photo: HotelPhotoWithPreview): void {
    if (this.isPhotoSelected(photo)) {
      this.selectedPhotos.delete(photo);
    } else {
      this.selectedPhotos.add(photo);
    }
  }

  isPhotoSelected(photo: HotelPhotoWithPreview): boolean {
    return this.selectedPhotos.has(photo);
  }

  selectPhoto(isChecked: boolean, photo: HotelPhotoWithPreview): void {
    if (isChecked) {
      this.selectedPhotos.add(photo);
    } else {
      this.selectedPhotos.delete(photo);
    }
  }

  showMorePhotos(): void {
    this.visiblePhotos = this.photos;
    this.hiddenPhotosCount = 0;
  }

  showGallery(photo: HotelPhotoWithPreview): void {
    const galleryComponentRef = this.galleryService.showGallery(
      this.getImagesForViewer(this.photos),
      this.photos.indexOf(photo),
      new Set(this.getImagesForViewer([...this.selectedPhotos])),
    );

    galleryComponentRef.instance.closed.subscribe(() => {
      this.isGalleryOpen = false;
    });
    galleryComponentRef.instance.imagesSelected.subscribe(images => {
      this.selectedPhotos = new Set(this.photos.filter(basePhoto => images.has(basePhoto.imageForViewer)));
      this.cdr.detectChanges();
    });
    this.isGalleryOpen = true;
  }

  private getImagesForViewer(photos: HotelPhotoWithPreview[]): GalleryItem[] {
    return photos.map(photo => photo.imageForViewer);
  }

  getMessagesForSend(): Observable<ContentCreatorItemMessage[]> {
    const s3FileUrls = Array.from(this.selectedPhotos).map(photo => photo.s3Url);
    if (!s3FileUrls.length) {
      return of([] as ContentCreatorItemMessage[]);
    }
    return of(
      s3FileUrls.map(s3FileUrl => ({
        component: this,
        message: { fileUrl: s3FileUrl },
      })),
    );
  }
}
