import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetTourContentRequest } from '@api-clients/api-client';
import { GetTourContentManagerOfferTourRequest } from '@api-clients/api-client/models/get-tour-content-manager-offer-tour-request';
import { TourContent } from '@api-clients/api-client/models/tour-content';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from '../../../../../../backend/src/models/models';
import { AppConfig } from '../../../../environments/environment';
import { apiResponsePipe } from '../../../api-response.pipe';
import { ApiContentType, ContentCreatorManagerOfferCardProps } from '../interfaces/content-creator.interface';

@Injectable({
  providedIn: 'root',
})
export class ContentCreatorApiService {
  constructor(private http: HttpClient) {}

  getTourContent(
    tourId: string,
    expands?: ApiContentType[],
    crmCardProps?: ContentCreatorManagerOfferCardProps,
  ): Observable<TourContent> {
    const request: GetTourContentRequest = {
      tourId,
      expands,
      managerOfferExpandParams: undefined,
    };

    if (crmCardProps) {
      request.managerOfferExpandParams = {
        tours: [{ tourId }],
        ...crmCardProps,
      };
    }

    return this.http
      .post<ApiResponse<TourContent>>(`${AppConfig.commonApiUrl}/content-creator/get-tour-content`, {
        ...request,
      })
      .pipe(
        apiResponsePipe,
        map(response => response as TourContent),
      );
  }

  getTourContentManagerOffer(
    tourIds: string[],
    crmCardProps?: ContentCreatorManagerOfferCardProps,
  ): Observable<TourContent> {
    const expands = [ApiContentType.ManagerOffer];
    const request: GetTourContentRequest = {
      tourId: tourIds[0],
      expands,
      managerOfferExpandParams: {
        tours: tourIds.map(tourId => {
          const tour: GetTourContentManagerOfferTourRequest = {
            tourId,
          };

          return tour;
        }),
      },
    };

    if (crmCardProps) {
      request.managerOfferExpandParams = {
        ...request.managerOfferExpandParams,
        ...crmCardProps,
      };
    }

    return this.http
      .post<ApiResponse<TourContent>>(`${AppConfig.commonApiUrl}/content-creator/get-tour-content`, {
        ...request,
      })
      .pipe(
        apiResponsePipe,
        map(response => response as TourContent),
      );
  }
}
