import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnInit,
  signal,
} from '@angular/core';
import { CategoryClipItem } from '@api-clients/api-client';
import { TourContent } from '@api-clients/api-client/models/tour-content';
import { Observable, of } from 'rxjs';
import { GalleryItem } from '../../../../../interfaces/gallery.interface';
import { GalleryService } from '../../../../../shared/services/gallery.service';
import {
  AlertLabelComponent,
  AlertLabelType,
} from '../../../../../ui-components/alert-label/alert-label.component';
import { CheckboxRoundedComponent } from '../../../../../ui-components/checkbox-rounded/checkbox-rounded.component';
import {
  ContentCreatorItemComponent,
  ContentCreatorItemMessage,
} from '../../../interfaces/content-creator.interface';

interface VideoCategory {
  name: string;
  videos: GalleryItem[];
}

@Component({
  selector: 'app-tour-content-videos',

  imports: [CheckboxRoundedComponent, AlertLabelComponent],
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GalleryService],
})
export class VideosComponent implements ContentCreatorItemComponent, OnInit {
  @Input() public tourId: string;
  @Input() public tourContent: TourContent;
  @Input() public disableSelection = false;

  public categories: VideoCategory[] = [];
  public videos: GalleryItem[] = [];
  public promoVideos: GalleryItem[] = [];
  public selectedVideos = new Set<GalleryItem>();
  public videoWithVisiblePreview = signal<GalleryItem | null>(null);
  public showVideoPreviewTimer: ReturnType<typeof setTimeout> | null = null;
  public firstInCategoryVideos = new Set<GalleryItem>();
  public isGalleryOpen = false;

  protected readonly dangerAlertLabelType = AlertLabelType.danger;

  private readonly galleryService = inject(GalleryService);
  private readonly cdr = inject(ChangeDetectorRef);

  ngOnInit(): void {
    const promoVideosWithCategory = [];
    const promoCategoryId = 8;
    const promoVideoUrls = new Set();
    if (this.tourContent.hotel?.videos) {
      this.categories = this.tourContent.hotel.videos.map(
        (category: CategoryClipItem): VideoCategory => ({
          name: category.name,
          videos: category.clips.map(video => {
            const imageItem = {
              id: `${video.id}`,
              previewUrl: video.previewUrl,
              contentLink: video.url,
              text: category.name,
              isVideo: true,
            };
            const isPromo = video.isPromo || category.id === promoCategoryId;
            if (isPromo) {
              promoVideoUrls.add(video.url);
              if (category.id !== promoCategoryId) {
                promoVideosWithCategory.push(imageItem);
              }
            }
            return imageItem;
          }),
        }),
      );
    }
    const promoCategory = this.categories.find(category => category.name === 'Промо');
    // Нам надо удалить из промо категории видео, которые встречаются в других категориях,
    // чтобы не дублировать их в интерфейсе
    if (promoCategory) {
      promoCategory.videos = promoCategory.videos.filter(
        video => !promoVideosWithCategory.some(v => v.contentLink === video.contentLink),
      );
    }

    this.videos = this.categories.reduce((acc, category) => acc.concat(category.videos), [] as GalleryItem[]);
    this.promoVideos = this.videos.filter(video => promoVideoUrls.has(video.contentLink));
    this.firstInCategoryVideos = new Set(this.categories.map(category => category.videos[0]));

    if (this.promoVideos.length) {
      // Если есть промо видео, то выделим их
      this.selectedVideos = new Set(this.promoVideos);
    }
  }

  toggleVideoSelection(video: GalleryItem): void {
    if (this.isVideoSelected(video)) {
      this.selectedVideos.delete(video);
    } else {
      this.selectedVideos.add(video);
    }
  }

  showVideoPreview(video: GalleryItem): void {
    this.showVideoPreviewTimer = setTimeout(() => this.videoWithVisiblePreview.set(video), 200);
  }

  hideVideoPreview(): void {
    clearTimeout(this.showVideoPreviewTimer);
    this.videoWithVisiblePreview.set(null);
  }

  isVideoSelected(video: GalleryItem): boolean {
    return this.selectedVideos.has(video);
  }

  isFirstInCategory(video: GalleryItem): boolean {
    return this.firstInCategoryVideos.has(video);
  }

  isPromo(video: GalleryItem): boolean {
    return this.promoVideos.includes(video);
  }

  selectVideo(isChecked: boolean, video: GalleryItem): void {
    if (isChecked) {
      this.selectedVideos.add(video);
    } else {
      this.selectedVideos.delete(video);
    }
  }

  showGallery(video: GalleryItem): void {
    const videoIndex = this.videos.indexOf(video);
    const galleryComponentRef = this.galleryService.showGallery(this.videos, videoIndex, this.selectedVideos);
    galleryComponentRef.instance.closed.subscribe(() => {
      this.isGalleryOpen = false;
    });
    galleryComponentRef.instance.imagesSelected.subscribe(images => {
      this.selectedVideos = images;
      this.cdr.detectChanges();
    });
    this.isGalleryOpen = true;
  }

  getMessagesForSend(): Observable<ContentCreatorItemMessage[]> {
    return of(
      Array.from(this.selectedVideos).map(video => ({
        component: this,
        message: {
          fileUrl: video.contentLink,
          contentParams: {
            hotelVideoId: Number(video.id),
          },
        },
      })),
    );
  }
}
