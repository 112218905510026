import { NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  input,
  output,
  viewChild,
  effect,
  WritableSignal,
  signal,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isSignal } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { BasicLoaderComponent } from '../../basic-loader/basic-loader.component';
import { CloseModalButtonComponent } from '../../close-modal-button/close-modal-button.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'brx-input-search',
  templateUrl: './brx-input-search.component.html',
  styleUrls: ['./brx-input-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'brxInputSearch',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BrxInputSearchComponent),
      multi: true,
    },
  ],
  imports: [MatTooltip, CloseModalButtonComponent, BasicLoaderComponent, NgOptimizedImage],
})
export class BrxInputSearchComponent implements ControlValueAccessor {
  placeholder = input<string>('');
  showClearBtn = input<boolean>(true);
  isLoading = input<boolean>(false);
  isSearchOnIconClick = input<boolean>(false);
  /**
   * Покажет лоадер только через время, чтобы у пользователя не было ощущения, что все тормозит
   */
  isDelayedLoading = input(true);
  isLoadingTimer: ReturnType<typeof setTimeout> | null = null;
  isLoadingForView = signal<boolean>(false);

  inputEvent = output<string>();
  enterKeyPressed = output<void>();
  clearSearchPressed = output<void>();

  value: string = '';
  valueSignal: WritableSignal<string> | null = null;

  searchInput = viewChild<ElementRef>('searchInputElement');

  onChange = (value: string) => {};
  onTouched = () => {};

  constructor() {
    effect(() => {
      // Работа с отложенным показом лоадера
      clearTimeout(this.isLoadingTimer);
      if (this.isDelayedLoading()) {
        if (this.isLoading()) {
          this.isLoadingTimer = setTimeout(() => {
            this.isLoadingForView.set(true);
          }, 700);
        } else {
          this.isLoadingForView.set(false);
        }
      } else {
        this.isLoadingForView.set(this.isLoading());
      }
    });
    effect(() => {
      if (this.valueSignal) {
        this.value = this.valueSignal();
        if (this.searchInput()) {
          this.searchInput().nativeElement.value = this.value;
        }
      }
    });
  }

  iconClick() {
    if (this.isSearchOnIconClick() && this.value) {
      this.enterKeyPressed.emit();
    } else {
      this.searchInput().nativeElement.focus();
    }
  }

  clearSearch(): void {
    this.value = '';
    if (this.searchInput()) {
      const nativeElement = this.searchInput().nativeElement;
      nativeElement.value = '';
      nativeElement.focus();
    }
    if (this.valueSignal) {
      this.valueSignal.set(this.value);
    }
    this.onChange(this.value);
    this.inputEvent.emit(this.value);
    this.clearSearchPressed.emit();
  }

  handleInput(event: Event): void {
    this.value = (event.target as HTMLInputElement).value;
    if (this.valueSignal) {
      this.valueSignal.set(this.value);
    }
    this.onChange(this.value);
    this.inputEvent.emit(this.value);
  }

  writeValue(value: string | WritableSignal<string>): void {
    if (isSignal(value)) {
      this.valueSignal = value;
      this.value = value();
    } else {
      this.value = value || '';
      this.valueSignal = null;
    }
    if (this.searchInput()) {
      this.searchInput().nativeElement.value = this.value;
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.searchInput()) {
      this.searchInput().nativeElement.disabled = isDisabled;
    }
  }
}
