import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { HotelTag, HotelTagListRequest, TopHotelListItem } from '@api-clients/api-client';
import { TopHotelListRequest } from '@api-clients/api-client/models';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AppConfig } from '../../../../../../../../environments/environment';
import { apiResponsePipe } from '../../../../../../../api-response.pipe';
import { HotelTagWithPriority } from '../search-tours.model';

@Injectable({ providedIn: 'root' })
export class SearchHotelTagsService {
  private readonly http = inject(HttpClient);

  private pendingRequests = new Map<string, Subject<HotelTag[]>>();
  private cacheTTL = 600000;

  loadTags$(request: HotelTagListRequest): Observable<HotelTag[]> {
    const key = JSON.stringify(request);
    const cachedResponse = this.getTagsListCachedResponse(key);

    if (cachedResponse) {
      return of(cachedResponse);
    }

    if (this.pendingRequests.has(key)) {
      return this.pendingRequests.get(key).asObservable();
    }

    const subject = new Subject<HotelTag[]>();
    this.pendingRequests.set(key, subject);

    this.http
      .post(`${AppConfig.apiUrl}/search-tags/tags-list`, request)
      .pipe(
        catchError(error => {
          subject.error(error);
          this.pendingRequests.delete(key);
          return of(null);
        }),
        apiResponsePipe,
        tap(response => {
          this.setTagsCachedResponse(key, response);
          this.pendingRequests.delete(key);
          subject.next(response);
          subject.complete();
        }),
      )
      .subscribe();

    return subject.asObservable();
  }

  loadHotelIdsByTags$(request: TopHotelListRequest): Observable<Map<number, HotelTagWithPriority[]>> {
    const selectedIds = request.tagIds;

    return this.http.post(`${AppConfig.apiUrl}/search-tags/hotels-by-tags`, request).pipe(
      apiResponsePipe,
      map((items: TopHotelListItem[]) => {
        const tags = new Map<number, HotelTagWithPriority[]>();
        if (items.length) {
          items.forEach(item => {
            tags.set(
              item.hotel.id,
              item.tags.map(tag => {
                return {
                  priority: selectedIds.findIndex(id => tag.id === id),
                  tag,
                };
              }),
            );
          });
        }

        return tags;
      }),
    );
  }

  flushTagsByHotels(): void {
    const tags = new Map<number, HotelTagWithPriority[]>();
    // this.tagsSub.next(tags);
  }

  private getTagsListCachedResponse(key: string): HotelTag[] | null {
    const cached = localStorage.getItem(key);
    if (cached) {
      const cachedData = JSON.parse(cached);
      if (new Date().getTime() - cachedData.timestamp < this.cacheTTL) {
        return cachedData.response;
      } else {
        localStorage.removeItem(key);
      }
    }
    return null;
  }

  private setTagsCachedResponse(key: string, response: any): void {
    const cachedData = {
      response,
      timestamp: new Date().getTime(),
    };
    localStorage.setItem(key, JSON.stringify(cachedData));
  }
}
