<div class="search-control-container">
  <div
    class="icon-search-wrapper"
    (click)="iconClick()"
    [class.cursor-pointer]="isSearchOnIconClick() && value"
  >
    <img class="icon-search" ngSrc="assets/icons/chats/search.svg" width="14" height="14" />
  </div>
  <input
    #searchInputElement
    (input)="handleInput($event)"
    (keydown.enter)="enterKeyPressed.emit()"
    type="text"
    class="search-control"
    [value]="value"
    (blur)="onTouched()"
    [placeholder]="placeholder()"
  />

  @if (isLoadingForView()) {
    <app-basic-loader [width]="20" [isDelayed]="false" />
  } @else {
    @if (showClearBtn() && searchInput().nativeElement.value.length) {
      <app-close-modal-button
        [isAbsolute]="false"
        [iconSize]="9"
        class="btn-clear"
        (click)="clearSearch()"
        matTooltip="Очистить"
      />
    }
  }
</div>
